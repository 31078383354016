import { hasNoValue } from "@xxl/common-utils";
import React from "react";
import { PAGE_QUERY_PARAMETER, SET_PAGE } from "../Constants";
import { useSearchContext } from "../SearchState";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Pagination } from "next-js-app/src/components/Pagination";
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  EXTRA_MARGIN_ON_DESKTOP,
  EXTRA_MARGIN_ON_MOBILE,
  NR_OF_PRODUCTS_PER_PAGE,
  SCROLL_DELAY_IN_MS,
} from "next-js-app/src/components/ProductListV2/constants";
import {
  DESKTOP_HEIGHT_WITH_HEADER_LINKS,
  MOBILE_HEIGHT_WITH_HEADER_LINKS,
} from "../../../components/Header/constants";
import { DUMMY_URL } from "../../../constants";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";

const FetchMoreDataButton = ({
  scrollToRef,
}: {
  scrollToRef: HTMLElement | null;
}) => {
  const { state, dispatch } = useSearchContext();
  const { page, relativePageUrl } = state;
  const { isFetchingMoreProducts } = state;
  const { count = 0 } = state.searchData?.results ?? {};
  const currentPage = page > 1 ? page : 1;
  const pageCount = Math.ceil(count / NR_OF_PRODUCTS_PER_PAGE);
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");

  const scrollToFilters = () => {
    if (hasNoValue(scrollToRef)) {
      return;
    }

    const offset = isLaptop
      ? -DESKTOP_HEIGHT_WITH_HEADER_LINKS - EXTRA_MARGIN_ON_DESKTOP
      : -MOBILE_HEIGHT_WITH_HEADER_LINKS - EXTRA_MARGIN_ON_MOBILE;
    const top =
      scrollToRef.getBoundingClientRect().top + window.scrollY + offset;

    setTimeout(function () {
      window.scrollTo({ top });
    }, SCROLL_DELAY_IN_MS);
  };

  const handlePaginationClick = (pageNo: number) => {
    dispatch({ type: SET_PAGE, payload: pageNo });
    scrollToFilters();
  };

  const getRelativePagingUrl = (relativeUrl: string | null, pageNo: number) => {
    // Next provides a relativePageUrl based on the request to the server. If react-app, grab values from window.location.
    const tempUrl = new URL(
      `${DUMMY_URL}${
        relativeUrl ?? window.location.pathname + window.location.search
      }`
    );
    const pageForLink = pageNo > 1 ? pageNo : 1;
    const nextPage = pageForLink + 1;
    tempUrl.searchParams.set(PAGE_QUERY_PARAMETER, nextPage.toString());
    return tempUrl.toString().replace(DUMMY_URL.toLocaleLowerCase(), "");
  };
  const relativePagingUrl = getRelativePagingUrl(relativePageUrl, page);

  return (
    <Pagination
      relativePagingUrl={relativePagingUrl}
      page={currentPage}
      pageCount={pageCount}
      onChange={handlePaginationClick}
      disabled={isFetchingMoreProducts}
    />
  );
};

export { FetchMoreDataButton };
