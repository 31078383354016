import { hasValue } from "@xxl/common-utils";
import type { V3CategoryData } from "@xxl/pim-api";
import type { Breadcrumb } from "./Breadcrumbs";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ROOT_CATEGORY } from "next-js-app/src/utils/category-page-information/constants";

const isValidV3CategoryDataBreadcrumb = (b: V3CategoryData): b is Breadcrumb =>
  hasValue(b.name) && hasValue(b.url);

const cleanBreadcrumb = (
  breadcrumb: { name: string; url: string },
  translatedHomeLabel: string
) => {
  const { name } = breadcrumb;
  if (name === ROOT_CATEGORY) {
    return {
      ...breadcrumb,
      name: translatedHomeLabel,
      url: "/",
    };
  }

  return breadcrumb;
};

export { cleanBreadcrumb, isValidV3CategoryDataBreadcrumb };
