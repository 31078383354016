import { CampaignPageApi } from "@xxl/frontend-api";
import type { Dispatch } from "react";
import { CAMPAIGN_PAGE_REQUEST_SUCCESS } from "./Constants";
import type { Action } from "./SearchState";
import { log } from "@xxl/logging-utils";

export const fetchCampaignPageData = async (
  basePath: string,
  id: string,
  dispatch: Dispatch<Action>
): Promise<void> => {
  try {
    const campaignPageApi = new CampaignPageApi({
      basePath,
    });
    const { data } =
      await campaignPageApi.campaignPageContentControllerGetCampaignPageContentGET(
        id
      );
    dispatch({
      type: CAMPAIGN_PAGE_REQUEST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    log.error("Error fetching campaign data", error);
  }
};

export const fetchCampaignData = (
  basePath: string,
  id: string,
  dispatch: Dispatch<Action>
): void => void fetchCampaignPageData(basePath, id, dispatch);
