export const FACETS_SEPARATOR = "--" as const;
export const VALUES_SEPARATOR = ":" as const;
export const PREFIX_SEPARATOR = "-" as const;

export const QUERY_SEPARATOR = "?" as const;
export const QUERY_JOINER = "&" as const;
export const CATEGORY_URL_MARKER = "/c/" as const;

export const CATEGORY_URL_PATH_PATTERN =
  `^(.*)(${CATEGORY_URL_MARKER}[^/?]*)(.*)$` as const;
export const CATEGORY_LONG_TAIL_PATTERN = "/(?:[^/]*/)*?(?:([^/]+)/)?" as const;
export const CATEGORY_PATH_FACET_SEPARATOR_TRIM_PATTERN =
  `(?<=\\S)--+$` as const;

export const REDIRECT_DIRECTIVE = "redirect:" as const;

export const ROOT_CATEGORY = "Root";

export const LONG_TAIL_FACET_COLOR_SOLR = "style";
export const LONG_TAIL_FACET_BRAND_SOLR = "brandName";
export const LONG_TAIL_FACET_SIZE_SOLR = "size";

export const LONG_TAIL_FACET_COLOR_ELEVATE = "style";
export const LONG_TAIL_FACET_BRAND_ELEVATE = "brand";
export const LONG_TAIL_FACET_SIZE_ELEVATE = "size";

export const COMPANY_TITLE_KEY = "meta.title.default";
export const DEFAULT_COMPANY_NAME = "XXL";

export const MAX_ALLOWED_VALUES_FOR_INDEX = 1;
