import { useState, useEffect } from "react";
import type { RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import type {
  GridCampaignCarousel,
  GridCarousel,
  OneCardContentComponent,
} from "@xxl/content-api";
import { useSharedData } from "../../contexts/SharedData";
import { legacySiteUidToSiteUid } from "../../utils/xxl-shared-data";
import type { PersonalizedData } from "../PersonalizedProductList/personalized-product-list-helper";
import {
  ListStyles,
  fetchData as fetchRecommendationsData,
} from "../PersonalizedProductList/personalized-product-list-helper";
import {
  CAMPAIGN_CAROUSEL_KEY,
  MAX_PRODUCT_COUNT_FOR_RECOMMENDATIONS,
  RECOMMENDATIONS_CAROUSEL_KEY,
} from "./constants";
import { useApiClients } from "../../contexts/ApiClients";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";

type GridExtrasData = {
  productsRecommendationsCarousel: GridCarousel | undefined;
  campaignCarousel: GridCampaignCarousel | undefined;
  oneCardContentComponents: OneCardContentComponent[];
};

export function useGridExtras(
  relativePageUrl: string | null,
  nrOfPages: number,
  togglePlpOneCardContentComponent: boolean
) {
  const [gridExtasData, setGridExtrasData] = useState<GridExtrasData>();
  const [personalizedData, setPersonalizedData] = useState<PersonalizedData>();
  const { pageType, siteUid: legacySiteUid } = useSharedData().data;

  const { recommendationsApi, contentApi } = useApiClients();

  useEffect(() => {
    const fetchGridExtras = async () => {
      if (
        pageType === "brand" ||
        pageType === "category" ||
        pageType === "campaign" ||
        pageType === "search"
      ) {
        let url = relativePageUrl;
        if (typeof window !== "undefined" && relativePageUrl === null) {
          url = window.location.pathname;
        }
        const siteUid = legacySiteUidToSiteUid(legacySiteUid);

        const code =
          pageType === "search"
            ? undefined
            : pageType === "brand"
              ? url?.split("/").slice(-2)[0]
              : url?.split("/").pop()?.split("?").shift();
        const { data } = await contentApi.getPlpComponents(
          siteUid,
          pageType,
          code ? decodeURI(code) : code
        );
        if (isNotNullOrUndefined(data)) {
          const gridExtras: GridExtrasData = {
            productsRecommendationsCarousel: data.gridCarousels[0],
            campaignCarousel: data.gridCampaignCarousels[0],
            oneCardContentComponents: data.oneCardContentComponents,
          };
          setGridExtrasData(gridExtras);
        }
      }
    };
    try {
      void fetchGridExtras();
    } catch (error) {
      log.error(error);
    }
  }, [
    pageType,
    contentApi,
    legacySiteUid,
    relativePageUrl,
    togglePlpOneCardContentComponent,
  ]);

  useEffect(() => {
    if (gridExtasData === undefined) {
      return;
    }
    const { productsRecommendationsCarousel, campaignCarousel } = gridExtasData;

    // we need to ask for more products than we need, because of the problem with data on TEST environment
    const productsMultiplier = process.env.NODE_ENV === "production" ? 1 : 3;
    const recommendationsProductsCount = Math.min(
      (productsRecommendationsCarousel?.productsCount ?? 1) * nrOfPages,
      MAX_PRODUCT_COUNT_FOR_RECOMMENDATIONS
    );
    const campaignProductsCount =
      (campaignCarousel?.productsCount ?? 0) * productsMultiplier;

    const recommendations = [];
    const requestPayloadForRecommendationsCarousel =
      productsRecommendationsCarousel !== undefined
        ? {
            listStyle: ListStyles.HORIZONTAL,
            productsCount: recommendationsProductsCount,
            recommendationsKey: RECOMMENDATIONS_CAROUSEL_KEY,
            strategy:
              productsRecommendationsCarousel.strategy as RecommendationsParametersStrategyEnum,
            includedProducts: productsRecommendationsCarousel.productIds,
            brandNames: productsRecommendationsCarousel.brandNames,
            categoryCodes: productsRecommendationsCarousel.categoryCodes,
            campaignIds: productsRecommendationsCarousel.campaignIds,
          }
        : undefined;

    const requestPayloadForCampaignCarousel =
      campaignCarousel !== undefined
        ? {
            listStyle: ListStyles.HORIZONTAL,
            productsCount: campaignProductsCount,
            recommendationsKey: CAMPAIGN_CAROUSEL_KEY,
            strategy:
              campaignCarousel.strategy as RecommendationsParametersStrategyEnum,
            includedProducts: campaignCarousel.productIds,
            brandNames: campaignCarousel.brandNames,
            categoryCodes: campaignCarousel.categoryCodes,
            campaignIds: campaignCarousel.campaignIds,
          }
        : undefined;

    if (requestPayloadForRecommendationsCarousel !== undefined) {
      recommendations.push(requestPayloadForRecommendationsCarousel);
    }
    if (requestPayloadForCampaignCarousel !== undefined) {
      recommendations.push(requestPayloadForCampaignCarousel);
    }

    const fetchData = fetchRecommendationsData(
      recommendations,
      setPersonalizedData,
      recommendationsApi,
      legacySiteUid
    );

    if (recommendations.length > 0) {
      void fetchData();
    }
  }, [recommendationsApi, gridExtasData, nrOfPages, legacySiteUid]);

  return {
    personalizedData,
    gridExtasData,
  };
}
