import { resolutionSizes } from "../../../config";
import { useSearchContext } from "../../Search/SearchState";
import { CATEGORY_GUIDES_REQUEST_SUCCESS, CHANGE_CATEGORY } from "../Constants";
import { CATEGORY_LEVEL_3 } from "../../../constants";
import type { GuidePreviewContentData } from "@xxl/frontend-api";
import { log } from "@xxl/logging-utils";

export const useSubcategory = (): {
  onCategoryClick: (
    categoryId?: string | undefined,
    categoryLevel?: number | undefined
  ) => (event: React.MouseEvent<HTMLAnchorElement>) => void;
} => {
  const { dispatch } = useSearchContext();

  const MOBILE_HEADER_BOTTOM = 140;
  const DESKTOP_HEADER_BOTTOM = 206;

  const onCategoryClick =
    (categoryId?: string, categoryLevel?: number | undefined) =>
    (event: React.MouseEvent<HTMLAnchorElement>): void => {
      if (
        categoryId !== undefined &&
        (categoryLevel === undefined || categoryLevel >= CATEGORY_LEVEL_3)
      ) {
        event.preventDefault();
        dispatch({
          payload: { categoryId },
          type: CHANGE_CATEGORY,
        });

        const isMobileHeader = window.innerWidth < resolutionSizes.laptop;
        if (isMobileHeader && window.scrollY > MOBILE_HEADER_BOTTOM) {
          window.scrollTo(0, MOBILE_HEADER_BOTTOM);
        } else if (!isMobileHeader && window.scrollY > DESKTOP_HEADER_BOTTOM) {
          window.scrollTo(0, DESKTOP_HEADER_BOTTOM);
        }

        fetch(`/api/content/relatedguides/${categoryId}`)
          .then(async (resp) => {
            if (resp.ok) {
              const data = (await resp.json()) as GuidePreviewContentData[];
              dispatch({
                type: CATEGORY_GUIDES_REQUEST_SUCCESS,
                payload: data,
              });
            }
            return true;
          })
          .catch((err) => log.error(err));
      }
    };

  return { onCategoryClick };
};
