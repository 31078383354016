import type {
  EntityCollection,
  SearchRequest,
  SearchResponse,
  AutoCompleteRequest,
  AutoCompleteResponse,
  SearchApi,
} from "@xxl/search-api";
import {
  QuerySortingParameterTypeEnum,
  QuerySortingParameterOrderEnum,
} from "@xxl/search-api";
import type { AxiosResponse } from "axios";
import {
  BRAND_FACET_ATTRIBUTE_NAME,
  CATEGORY_FACET_ATTRIBUTE_NAME,
  IS_FETCHING_PRODUCTS,
  MINIMUM_QUERY_LENGTH,
  PRICE_FACET_ATTRIBUTE_NAME,
  PRICE_VALUE_FACET_ATTRIBUTE_NAME,
  SEARCH_REQUEST_FAILED,
  SEARCH_REQUEST_SUCCESS,
  TABS_CREATE_SUCCESS,
  SET_BRANDS_CONTENT,
  SET_GUIDES_CONTENT,
  SET_STORES_CONTENT,
  SET_FAQ_CONTENT,
  SET_SEARCH_SUGGESTIONS,
  WAREHOUSE_ATTRIBUTE_NAME,
  FACET_CATEGORY_ID,
  MULTICHANNEL_AVAILABILITY_ATTRIBUTE_NAME,
} from "./Constants";
import { GetSearchFetchStrategy } from "./GetSearchFetchStrategy";
import { PostSearchFetchStrategy } from "./PostSearchFetchStrategy";
import type { SearchFetchStrategy } from "./SearchFetchStrategy";
import type { Action, FacetList, State } from "./SearchState";
import { getPreferredStoresCookie } from "../../utils/Cookie";
import type { Translate } from "../../contexts/Translations/TranslationsContext";
import type {
  AutoCompleteResponseAugmented,
  EntityCollectionAugmented,
  SearchResponseAugmented,
} from "./SearchFetchProductsHelper.types";
import { AVAILABILITY } from "./SearchFetchProductsHelper.types";
import type { EcomSiteUidLegacy } from "../../global";
import { forceSolrAsProviderRegex } from "./SearchStateUrlHelper";
import type { Trackers } from "../../contexts/Tracking";
import type {
  DistinctFacet,
  DistinctFacetParameter,
  Filter,
  RangeFacet,
  RangeFacetParameter,
  SearchRequestProvider,
} from "../../utils/data-types";
import { CONTENT_NAMES } from "../../api/elevate-api";
import { log } from "@xxl/logging-utils";

interface PrepareRequest {
  searchRequests: SearchRequest[];
  initialResponse?: AxiosResponse<SearchResponse>;
}

let latestFetchRequestId = 0;

const TABS_TAKE_NUMBER = 100;

const getSkipValue = (page: number, pageSize: number) => {
  const isFirstPage = page <= 1; // both 0 and 1 are considered first page
  return isFirstPage ? 0 : (page - 1) * pageSize;
};

export const createRequestId = (): number => {
  const newId = latestFetchRequestId + 1;
  latestFetchRequestId = newId;
  return newId;
};

const isLatestRequest = (requestId: number): boolean =>
  requestId === latestFetchRequestId;

const shouldGetFacetsForProvider =
  (provider: SearchRequestProvider) =>
  ({ attributeName }: Filter): boolean => {
    switch (attributeName) {
      case PRICE_VALUE_FACET_ATTRIBUTE_NAME: {
        return provider === "loop";
      }
      case PRICE_FACET_ATTRIBUTE_NAME: {
        return provider === "solr";
      }
      default: {
        return true;
      }
    }
  };

const getPreferredStoresFilter = () => {
  const preferredStores = getPreferredStoresCookie();

  const availableOnline =
    preferredStores?.availability.includes(AVAILABILITY.ONLINE) ?? false;
  const availableInStore =
    preferredStores?.availability.includes(AVAILABILITY.STORE) ?? false;
  const numberOfPreferredStores = preferredStores?.ids.length ?? 0;

  const isPreferredStoresCookieEmpty = preferredStores === null;
  const isNoAvailabilitySelected = !availableInStore && !availableOnline;
  const isOnlyOnline = availableOnline && !availableInStore;

  if (isPreferredStoresCookieEmpty) {
    return [
      {
        or: [
          {
            attributeName: MULTICHANNEL_AVAILABILITY_ATTRIBUTE_NAME,
            value: AVAILABILITY.ONLINE,
          },
          {
            attributeName: MULTICHANNEL_AVAILABILITY_ATTRIBUTE_NAME,
            value: AVAILABILITY.STORE,
          },
        ],
      },
    ];
  }

  if (isNoAvailabilitySelected || isOnlyOnline) {
    return [];
  }

  const onlineAvailabilityFilter = {
    attributeName: MULTICHANNEL_AVAILABILITY_ATTRIBUTE_NAME,
    value: AVAILABILITY.ONLINE,
  };

  const warehouseFilter =
    numberOfPreferredStores === 1
      ? {
          attributeName: WAREHOUSE_ATTRIBUTE_NAME,
          value: preferredStores.ids[0],
        }
      : {
          or: preferredStores.ids.map((id) => ({
            attributeName: WAREHOUSE_ATTRIBUTE_NAME,
            value: id,
          })),
        };

  const storeAvailabilityFilter =
    numberOfPreferredStores === 0
      ? {
          attributeName: MULTICHANNEL_AVAILABILITY_ATTRIBUTE_NAME,
          value: AVAILABILITY.STORE,
        }
      : {
          and: [
            {
              attributeName: MULTICHANNEL_AVAILABILITY_ATTRIBUTE_NAME,
              value: AVAILABILITY.STORE,
            },
            warehouseFilter,
          ],
        };

  function getAvailabilityFilter() {
    if (!availableInStore) {
      return onlineAvailabilityFilter;
    }
    if (!availableOnline) {
      return storeAvailabilityFilter;
    }
    return { or: [onlineAvailabilityFilter, storeAvailabilityFilter] };
  }

  return [getAvailabilityFilter()];
};

const getSearchRequests = (
  state: State,
  startingPage = 0,
  pages: number = state.page,
  selectedFilters: Filter[] = state.selectedFilters
): SearchRequest[] => {
  const previousPagesSearchRequests: SearchRequest[] = [];
  const { categoryPath } = state;

  const selectedCategoryCode = [...categoryPath].pop();
  const categoryFacet: DistinctFacetParameter[] =
    selectedCategoryCode !== undefined
      ? [
          {
            attributeName: FACET_CATEGORY_ID,
            selected: [selectedCategoryCode],
          },
        ]
      : [];
  const facets = [
    ...selectedFilters,
    ...state.initialSolrFilters,
    ...categoryFacet,
  ].filter(shouldGetFacetsForProvider(state.provider));

  const getPagedSearchRequest = (page: number): SearchRequest => {
    const { attribute, pageSize, provider, selectedSort, userGroups, query } =
      state;
    const filters = forceSolrAsProviderRegex.test(query)
      ? []
      : getPreferredStoresFilter();

    const skip = getSkipValue(page, pageSize);
    return {
      query,
      attribute,
      take: pageSize,
      skip: skip,
      sortBy: selectedSort,
      facets,
      filters,
      provider: query.includes("code:") ? "solr" : provider,
      usergroupids: userGroups,
    } as SearchRequest; // TODO: XD-13966
  };

  if (state.isInitialRequest) {
    for (let page = startingPage; page < pages; page++) {
      previousPagesSearchRequests.push(getPagedSearchRequest(page));
    }
  }

  const currentPageSearchRequest = getPagedSearchRequest(pages);
  return [...previousPagesSearchRequests, currentPageSearchRequest];
};

const requiresInitialFetch = (state: State): boolean => {
  return state.page > 0 && !state.isFetchingMoreProducts;
};

const prepareRequests = async (
  state: State,
  searchFetchStrategy: SearchFetchStrategy
): Promise<PrepareRequest> => {
  let searchRequests;
  const getMaxPage = (
    currentState: State,
    totalResultsCount: number | undefined
  ): number => {
    if (totalResultsCount !== undefined) {
      const maxPage =
        totalResultsCount === 0
          ? 0
          : Math.ceil(totalResultsCount / currentState.pageSize) - 1;
      return Math.min(currentState.page, maxPage);
    }

    return currentState.page;
  };

  if (requiresInitialFetch(state)) {
    searchRequests = getSearchRequests(state, 0, 1);
    const [firstRequest] = searchRequests;
    const initialResponse = (
      await searchFetchStrategy.doFetchProducts(
        state.siteId,
        [firstRequest],
        state.userId
      )
    )[0];
    const selectedFilters = state.selectedFilters.filter(
      (f: DistinctFacetParameter | RangeFacetParameter) => {
        return initialResponse.data.results?.acceptedFacets?.includes(
          f.attributeName
        );
      }
    );
    const maxPage = getMaxPage(state, initialResponse.data.results?.count);
    return {
      initialResponse,
      searchRequests:
        maxPage > 0 //on initial page load page 0 is loaded with initialResponse, this condition avoid showing duplicated results
          ? getSearchRequests(
              state,
              maxPage, //on initial page load show only products from initial response (page 0) and from requested page - pages between are omitted due to SEO optimization
              maxPage,
              selectedFilters
            )
          : [],
    };
  }

  return {
    searchRequests: getSearchRequests(state, 0, state.page),
  };
};

const handleSearchErrors = (
  err: unknown,
  _: State,
  dispatch: (action: Action) => void
): void => {
  log.error(err);
  dispatch({
    type: SEARCH_REQUEST_FAILED,
  });
};

const trackSearchResults = (
  response: SearchResponseAugmented,
  trackers: Trackers,
  campaignId?: string
): void => {
  const side = response.customData?.side;
  if (side !== undefined) {
    if (typeof campaignId === "string") {
      trackers.sendCampaignPageViewEvent(side);
    } else {
      trackers.sendSearchPageViewEvent(side);
    }
  }
};

export const handleSearchSuccess = (
  response: SearchResponseAugmented,
  requestId: number,
  dispatch: (action: Action) => void,
  t: Translate,
  state: State,
  tabsResponse: AutoCompleteResponseAugmented | null,
  trackers: Trackers
): void => {
  trackSearchResults(response, trackers, state.campaignId);
  const searchSuggestions: string[] = [];
  if (response.makesSense) {
    response.relatedQueries?.items?.forEach((item) =>
      searchSuggestions.push(item.query)
    );
  } else {
    response.spellingSuggestions?.items?.forEach((item) =>
      searchSuggestions.push(item.query)
    );
  }

  dispatch({
    type: SET_SEARCH_SUGGESTIONS,
    payload: { searchSuggestions, makeSense: response.makesSense },
  });

  const tabsContent = tabsResponse;
  const tabs = [];
  if (
    response.results !== null &&
    response.results.count !== undefined &&
    response.results.count > 0
  ) {
    tabs.push({
      id: CONTENT_NAMES.products,
      isActive: false,
      name: t("header.search.suggestions.products"),
      count: response.results.count,
    });
  }
  if (
    tabsContent !== null &&
    tabsContent.brandResults !== null &&
    tabsContent.brandResults.count !== undefined &&
    tabsContent.brandResults.count > 0
  ) {
    tabs.push({
      id: CONTENT_NAMES.brand,
      isActive: false,
      name: t("header.search.suggestions.brands"),
      count: tabsContent.brandResults.count,
    });
    dispatch({
      type: SET_BRANDS_CONTENT,
      payload: tabsContent.brandResults,
    });
  }
  if (
    tabsContent !== null &&
    tabsContent.guideResults !== null &&
    tabsContent.guideResults.count !== undefined &&
    tabsContent.guideResults.count > 0
  ) {
    tabs.push({
      id: CONTENT_NAMES.guide,
      isActive: false,
      name: t("header.search.suggestions.guides"),
      count: tabsContent.guideResults.count,
    });
    dispatch({
      type: SET_GUIDES_CONTENT,
      payload: tabsContent.guideResults,
    });
  }
  if (
    tabsContent !== null &&
    tabsContent.storeResults !== null &&
    tabsContent.storeResults.count !== undefined &&
    tabsContent.storeResults.count > 0
  ) {
    tabs.push({
      id: CONTENT_NAMES.store,
      isActive: false,
      name: t("header.search.suggestions.stores"),
      count: tabsContent.storeResults.count,
    });
    dispatch({
      type: SET_STORES_CONTENT,
      payload: {
        stores: tabsContent.storeResults,
      },
    });
  }
  if (
    tabsContent !== null &&
    tabsContent.faqHubResults !== null &&
    tabsContent.faqHubResults.count !== undefined &&
    tabsContent.faqHubResults.count > 0
  ) {
    tabs.push({
      id: CONTENT_NAMES.faqEntry,
      isActive: false,
      name: t("header.search.suggestions.faq"),
      count: tabsContent.faqHubResults.count,
    });
    dispatch({
      type: SET_FAQ_CONTENT,
      payload: tabsContent.faqHubResults,
    });
  }

  if (tabs.length > 0) {
    tabs[0].isActive = true;
    dispatch({
      type: TABS_CREATE_SUCCESS,
      payload: tabs,
    });
  }

  if (!isLatestRequest(requestId)) {
    return;
  }
  dispatch({
    type: SEARCH_REQUEST_SUCCESS,
    payload: response,
  });
};

function getFacetsToShow(
  facets?: (DistinctFacet | RangeFacet)[],
  categoryId?: string,
  brandName?: string
): FacetList {
  if (facets === undefined) {
    return [];
  }

  return categoryId !== undefined
    ? facets.filter(
        (f) =>
          !(f.attributeName ?? "").startsWith(CATEGORY_FACET_ATTRIBUTE_NAME)
      )
    : brandName !== undefined
      ? facets.filter(
          (f) => !(f.attributeName ?? "").startsWith(BRAND_FACET_ATTRIBUTE_NAME)
        )
      : facets;
}

const fetchTabsContent = async (
  query: string,
  takeNumber: number,
  loopUserId: string,
  searchApi: SearchApi,
  siteUid: EcomSiteUidLegacy
): Promise<AxiosResponse<AutoCompleteResponse>> => {
  const request: AutoCompleteRequest = {
    take: takeNumber,
    query: query.trim().substring(0, 100),
    skip: 0,
    sortBy: [
      {
        type: QuerySortingParameterTypeEnum.relevance,
        order: QuerySortingParameterOrderEnum.desc,
      },
    ],
    includeOnlyNonEmptyBrands: true,
  };

  return await searchApi.searchContent(siteUid, request, {
    headers: {
      "User-Id": loopUserId,
    },
  });
};

const extractSearchResponses = (
  responses: AxiosResponse<SearchResponse>[]
): SearchResponse[] => responses.map((response) => response.data);

const mergeEntityCollection = (
  collections: EntityCollection[],
  categoryId?: string,
  brandName?: string
): EntityCollectionAugmented => {
  if (collections.length === 0) {
    return {
      count: 0,
      acceptedFacets: [],
      facets: [],
      items: [],
    };
  }
  const lastCollection = collections[collections.length - 1];
  return {
    count: collections[0].count ?? 0,
    acceptedFacets: collections[0].acceptedFacets ?? [],
    facets: getFacetsToShow(collections[0].facets, categoryId, brandName),
    items: lastCollection.items ?? [],
  };
};

const mergeSearchResponses = (
  searchResponses: SearchResponse[],
  categoryId?: string,
  brandName?: string
): SearchResponseAugmented => {
  const results = searchResponses.reduce<EntityCollection[]>(
    (entities, currentEntity) => {
      if (currentEntity.results !== undefined) {
        entities.push(currentEntity.results);
      }

      return entities;
    },
    []
  );

  const relatedResults = searchResponses.reduce<EntityCollection[]>(
    (entities, currentEntity) => {
      if (currentEntity.relatedResults !== undefined) {
        entities.push(currentEntity.relatedResults);
      }

      return entities;
    },
    []
  );

  return {
    makesSense: (searchResponses[0].makesSense as boolean | undefined) ?? false,
    spellingSuggestions: searchResponses[0].spellingSuggestions ?? null,
    relatedQueries: searchResponses[0].relatedQueries ?? null,
    results: mergeEntityCollection(results, categoryId, brandName),
    relatedResults: mergeEntityCollection(
      relatedResults,
      categoryId,
      brandName
    ),
    customData: searchResponses[0].customData ?? null,
  };
};

const initiateFetchProducts = async (
  state: State,
  searchFetchStrategy: SearchFetchStrategy
) => {
  if (0 < state.query.length && state.query.length < MINIMUM_QUERY_LENGTH) {
    return Promise.reject(
      `Minimum allowed query length is ${MINIMUM_QUERY_LENGTH}`
    );
  }
  const prepareRequest = await prepareRequests(state, searchFetchStrategy);
  const responses = [
    ...(prepareRequest.initialResponse !== undefined
      ? [prepareRequest.initialResponse]
      : []),
    ...(await searchFetchStrategy.doFetchProducts(
      state.siteId,
      prepareRequest.searchRequests,
      state.userId
    )),
  ];

  if (responses.length === 0) {
    throw new Error("Did not get an expected response.");
  }
  return mergeSearchResponses(
    extractSearchResponses(responses),
    state.categoryId,
    state.brandName
  );
};

const getSearchFetchStrategy = (
  state: State,
  searchApi: SearchApi
): SearchFetchStrategy => {
  const searchFetchStrategies: SearchFetchStrategy[] = [
    new GetSearchFetchStrategy(searchApi),
    new PostSearchFetchStrategy(searchApi),
  ];

  const strategy = searchFetchStrategies.find((strategy) =>
    strategy.accepts(state)
  );

  if (strategy === undefined) {
    throw Error("No search strategy found.");
  }

  return strategy;
};

const fetchProducts = async (
  state: State,
  isSearchResultPage = false,
  loopUserId: string,
  searchApi: SearchApi,
  siteUid: EcomSiteUidLegacy
): Promise<{
  searchResponse: SearchResponseAugmented;
  tabsResponse: AutoCompleteResponseAugmented | null;
}> => {
  const searchResponse = await initiateFetchProducts(
    state,
    getSearchFetchStrategy(state, searchApi)
  );

  let tabsResponse: AxiosResponse<AutoCompleteResponse> | null = null;
  if (isSearchResultPage === true) {
    tabsResponse = await fetchTabsContent(
      state.query,
      TABS_TAKE_NUMBER,
      loopUserId,
      searchApi,
      siteUid
    );

    if (
      tabsResponse.data.guideResults !== undefined &&
      tabsResponse.data.guideResults.count !== undefined &&
      tabsResponse.data.guideResults.count > TABS_TAKE_NUMBER
    ) {
      tabsResponse = await fetchTabsContent(
        state.query,
        tabsResponse.data.guideResults.count,
        loopUserId,
        searchApi,
        siteUid
      );
    }
  }

  return {
    searchResponse,
    tabsResponse: {
      appResults: tabsResponse?.data.appResults ?? null,
      brandResults: tabsResponse?.data.brandResults ?? null,
      campaignHubResults: tabsResponse?.data.campaignHubResults ?? null,
      faqHubResults: tabsResponse?.data.faqHubResults ?? null,
      guideResults: tabsResponse?.data.guideResults ?? null,
      productResults: tabsResponse?.data.productResults ?? null,
      queries: tabsResponse?.data.queries ?? null,
      queryString: tabsResponse?.data.queryString ?? null,
      rewardResults: tabsResponse?.data.rewardResults ?? null,
      scopedQuery: tabsResponse?.data.scopedQuery ?? null,
      storeResults: tabsResponse?.data.storeResults ?? null,
    },
  };
};

const callSearchAndUpdateState = async (
  dispatch: React.Dispatch<Action>,
  isSearchResultPage: boolean,
  state: State,
  t: Translate,
  loopUserId: string,
  searchApi: SearchApi,
  siteUid: EcomSiteUidLegacy,
  trackers: Trackers
): Promise<void> => {
  dispatch({
    type: IS_FETCHING_PRODUCTS,
  });

  const requestId = createRequestId();
  try {
    const { searchResponse, tabsResponse } = await fetchProducts(
      state,
      isSearchResultPage,
      loopUserId,
      searchApi,
      siteUid
    );
    handleSearchSuccess(
      searchResponse,
      requestId,
      dispatch,
      t,
      state,
      tabsResponse,
      trackers
    );
  } catch (error) {
    const message =
      error instanceof Error ? error.message : "[fetchProducts] Unknown error.";
    handleSearchErrors(message, state, dispatch);
  }
};

export {
  callSearchAndUpdateState,
  fetchProducts,
  getFacetsToShow,
  getSearchFetchStrategy,
  shouldGetFacetsForProvider,
  getSkipValue,
};
