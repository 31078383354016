import { hasValue } from "@xxl/common-utils";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useSharedData } from "../../../contexts/SharedData";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import { isCampaignPage } from "../../../utils/xxl-page-type";
import { Breadcrumbs } from "../../Breadcrumbs";
import {
  cleanBreadcrumb,
  isValidV3CategoryDataBreadcrumb,
} from "../../Breadcrumbs/breadcrumbs-helper";
import { LongTailTitleComp } from "../LongTailTitleComp";
import { useSearchContext } from "../SearchState";
import { BuyingGuideHeaderCategoryHero } from "./BuyingGuideHeaderCategoryHero";
import {
  BreadcrumbsContainer,
  BuyingGuideContainer,
  H1,
  StyledHeader,
} from "./Header.styled";

export const Header: React.FunctionComponent = () => {
  const { t } = useTranslations();
  const { state } = useSearchContext();
  const { categoryInfo, isFetchingCategoryData, query, buyingGuideData } =
    state;
  const category = categoryInfo?.category;
  const { pageType } = useSharedData().data;
  const { toggle_plp_buying_guides } = useSharedData().data.featureToggles;
  const homeUrl = typeof window === "undefined" ? "" : window.location.origin;
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const homeLabel = t("breadcrumbs.home");
  const headerText = hasValue(query)
    ? `${t("search.page.search.phrase")} ${query}`
    : categoryInfo?.category?.header;
  const showBuyingGuide = toggle_plp_buying_guides && buyingGuideData !== null;
  const categoryName = category?.header ?? category?.breadcrumbs?.at(-1)?.name;
  const shouldRenderHeader = !showBuyingGuide && isLaptop;
  const breadcrumbs =
    state.categoryInfo?.category?.breadcrumbs
      ?.filter(isValidV3CategoryDataBreadcrumb)
      .map((breadcrumb) => cleanBreadcrumb(breadcrumb, homeLabel)) ?? [];

  return (
    <StyledHeader isLoading={isFetchingCategoryData}>
      <BreadcrumbsContainer className="container">
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          homeUrl={homeUrl}
          includeH1={true}
          shouldRenderCrumbsOnUserInteraction={pageType === "category"} // Todo: https://xxlsports.atlassian.net/browse/XD-15863
        />
      </BreadcrumbsContainer>
      {!isCampaignPage() && !state.isSearchResultPage && (
        <div className="container">
          {state.longTailTitle !== null &&
          category !== undefined &&
          category.name !== undefined ? (
            <LongTailTitleComp longTailTitle={state.longTailTitle} />
          ) : (
            shouldRenderHeader && <H1>{headerText}</H1>
          )}

          {category !== undefined &&
            isEmpty(state.longTailTitle) &&
            showBuyingGuide && (
              <BuyingGuideContainer>
                <BuyingGuideHeaderCategoryHero
                  buyingGuide={buyingGuideData}
                  categoryName={categoryName}
                />
              </BuyingGuideContainer>
            )}
        </div>
      )}
    </StyledHeader>
  );
};
